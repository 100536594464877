import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Box,
  Typography,
  SelectChangeEvent,
} from '@mui/material';
import { useListBuilder } from './ListBuilderProvider';
import { Controller } from 'react-hook-form';
import { crmApi } from 'redux/reducers/api/crm';
import { useUserState } from 'hooks/common/useUserState';
import { LoaderBlock } from 'components/common/Svgs/Loader';
import { useEffect, useMemo, useCallback } from 'react';
import { usePosthog } from 'analytics';
import { useWatch } from 'react-hook-form';

export function OrganizationsCRMInputForm() {
  const { team } = useUserState();
  const { form } = useListBuilder();
  const { sendPosthogEvent } = usePosthog();
  const { data, isLoading } = crmApi.useGetCrmFilterOptionsQuery(team.uuid);

  // Watch list related fields to add to analytics events
  const listName = useWatch({ control: form.control, name: 'listName' });
  const listType = useWatch({ control: form.control, name: 'listType' });
  const listEntityType = useWatch({
    control: form.control,
    name: 'listEntityType',
  });

  // Reset values when the component unmounts.
  useEffect(() => {
    return () => {
      form.resetField('includedDealStages');
      form.resetField('includedDealOwners');
    };
  }, [form]);

  // Consolidated handler for tracking CRM field changes
  const handleCRMFieldChange = useCallback(
    (fieldName: string, value: string[]) => {
      if (value.length > 0) {
        sendPosthogEvent('Prospect Navigator > CRM Field Changed', {
          listName,
          listType,
          listContents: listEntityType,
          fieldName,
          value,
        });
      }
    },
    [listName, listType, listEntityType, sendPosthogEvent]
  );

  const dealOwnerOptions = useMemo(
    () =>
      data?.success
        ? data.results.grouped_crm_filters.OWNER.map((item) => ({
            label: item.label,
            value: item.label, // DEV-3683
          }))
        : [],
    [data]
  );

  const dealStageOptions = useMemo(
    () =>
      data?.success
        ? data.results.grouped_crm_filters.DEAL_STAGE.map((item) => ({
            label: item.label,
            value: item.label, // DEV-3683
          }))
        : [],
    [data]
  );

  if (isLoading) {
    return <LoaderBlock />;
  }

  return (
    <Stack spacing={2} data-testid="organizations-crm-input-form">
      <FormControl fullWidth variant="outlined">
        <InputLabel id="deal-stage-select-label">Deal stage</InputLabel>
        <Controller
          name="includedDealStages"
          control={form.control}
          render={({ field }) => (
            <Select
              labelId="deal-stage-select-label"
              data-testid="deal-stage-select"
              label="Deal stage"
              multiple
              {...field}
              onChange={(e: SelectChangeEvent<string[]>) => {
                field.onChange(e);
                handleCRMFieldChange(
                  'includedDealStages',
                  e.target.value as string[]
                );
              }}
            >
              {dealStageOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>

      <FormControl fullWidth variant="outlined">
        <InputLabel id="deal-owner-select-label">Deal owner</InputLabel>
        <Controller
          name="includedDealOwners"
          control={form.control}
          render={({ field }) => (
            <Select
              labelId="deal-owner-select-label"
              data-testid="deal-owner-select"
              label="Deal owner"
              multiple
              {...field}
              onChange={(e: SelectChangeEvent<string[]>) => {
                field.onChange(e);
                handleCRMFieldChange(
                  'includedDealOwners',
                  e.target.value as string[]
                );
              }}
            >
              {dealOwnerOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>

      <Box sx={{ textAlign: 'center', my: 3 }}>
        <Typography>OR</Typography>
      </Box>

      <FormControl fullWidth variant="outlined">
        <InputLabel id="company-owner-select-label">Company owner</InputLabel>
        <Controller
          name="includedCompanyOwners"
          control={form.control}
          render={({ field }) => (
            <Select
              labelId="company-owner-select-label"
              data-testid="company-owner-select"
              label="Company owner"
              multiple
              {...field}
              onChange={(e: SelectChangeEvent<string[]>) => {
                field.onChange(e);
                handleCRMFieldChange(
                  'includedCompanyOwners',
                  e.target.value as string[]
                );
              }}
            >
              {dealOwnerOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </Stack>
  );
}
