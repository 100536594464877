import React, { useEffect, useMemo } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { prospectsApi } from 'redux/reducers/api/prospects';
import { LoaderBlock } from 'components/common/Svgs/Loader';
import { ListEntityType } from './types';
import { Controller } from 'react-hook-form';
import { useListBuilder } from './ListBuilderProvider';

export function ExistingListForm({
  entityType,
}: {
  entityType: ListEntityType;
}) {
  const { form } = useListBuilder();

  // Reset the field when the component unmounts.
  useEffect(() => {
    return () => {
      form.resetField('includedProspectLists');
    };
  }, [form]);

  const { data, isLoading } =
    prospectsApi.useGetMyProspectListsShortQuery(true);

  const options = useMemo(() => {
    if (!data?.success) {
      return [];
    }

    return data?.prospect_lists
      .filter((l) => l.list_entity_type === entityType)
      .map(({ uuid, name }) => ({
        value: uuid,
        label: name,
      }));
  }, [data, entityType]);

  if (isLoading) {
    return <LoaderBlock />;
  }

  if (!isLoading && !data?.success) {
    // TODO: error state
    return null;
  }

  const listTypeString = entityType === 'profiles' ? 'people' : 'organization';

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id={`existing-list-select-label-${entityType}`}>
        Select existing {listTypeString} list
      </InputLabel>

      <Controller
        name="includedProspectLists"
        control={form.control}
        render={({ field: { onChange, ...fieldProps } }) => {
          return (
            <Select
              labelId={`existing-list-select-label-${entityType}`}
              {...fieldProps}
              // We allow filtering only by a single list, but includedProspectLists is an array field.
              // This transforms the single-list string into an array.
              onChange={(event) => onChange([event.target.value])}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />
    </FormControl>
  );
}
