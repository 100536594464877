import { Box, Typography } from '@mui/material';
import { useListBuilder } from './ListBuilderProvider';
import { useCSVUploadContext } from './CSVUpload/CSVUploadContext';
import { VDXAutocomplete } from './VDXAutocomplete';
import { Controller } from 'react-hook-form';
import { FileInput } from './CSVUpload/FileInput';
import { JobTitleCSVColumnsForm } from './CSVUpload/JobTitleCSVColumnsForm';
import { useEffect } from 'react';

export function PeopleJobTitleForm({
  fieldName,
}: {
  fieldName: 'includedJobTitles' | 'excludedJobTitles';
}) {
  const { form } = useListBuilder();

  const {
    values,
    file,
    fetchedOptions,
    handleAutocompleteChange,
    handleFileInputChange,
    handleFileInputDrop,
    handleRemoveItem,
    handleTextInputChange,
    handleKeyDown,
  } = useCSVUploadContext();

  // Reset values when the component unmounts.
  useEffect(() => {
    return () => {
      form.resetField(fieldName);
    };
  }, [form, fieldName]);

  return (
    <Box data-testid={`people-job-title-form-${fieldName}`}>
      {/* File uploader */}
      <FileInput
        onChange={handleFileInputChange}
        onDrop={handleFileInputDrop}
      />

      {/* Column matcher */}
      {!!file ? <JobTitleCSVColumnsForm /> : null}

      {!file ? (
        <Box sx={{ textAlign: 'center', my: 3 }}>
          <Typography>OR</Typography>
        </Box>
      ) : null}

      {/* Autocomplete */}
      <Controller
        name={fieldName}
        control={form.control}
        render={({ field }) => (
          <VDXAutocomplete
            values={values}
            options={fetchedOptions}
            slotProps={{
              autocomplete: {
                freeSolo: true,
                limitTags: 5,
                onChange: handleAutocompleteChange,
                onKeyDown: handleKeyDown,
              },
              textfield: {
                ...field,
                placeholder: values.length
                  ? 'Enter more job titles (optional)..'
                  : 'Search job titles...',
                onChange: handleTextInputChange,
              },
              tags: {
                limitTags: 10,
              },
            }}
            onDeleteItem={handleRemoveItem}
          />
        )}
      />
    </Box>
  );
}
