import { Avatar } from '@mui/material';
import { CustomGridColDef } from 'components/common/DataGrid/DataGridProvider';
import {
  ProspectListOrganization,
  ProspectListProfile,
} from 'modules/prospects/types';
import { Link } from 'react-router-dom';

export const commonColumns: CustomGridColDef<
  ProspectListProfile | ProspectListOrganization
>[] = [
  {
    field: 'image',
    headerName: '',
    renderCell: ({ value, row }) => (
      <Avatar alt={row.name} src={value} sx={{ width: 24, height: 24 }} />
    ),
    resizable: false,
    disableColumnMenu: true,
    sortable: false,
    width: 10,
    // custom column definitions not in GridColDef
    filterable: false,
    hideable: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    renderCell: ({ value, row }) => <Link to={row.link}>{value}</Link>,
    flex: 3,
    // custom column definitions not in GridColDef
    filterable: false,
  },
  {
    field: 'events',
    headerName: '# of events',
    flex: 1,
    type: 'number',
    resizable: true,
    // custom column definitions not in GridColDef
    defaultFilter: true,
    valueGetterId: 'sortNumberIntoBuckets',
  },
];

export const profileColumns: CustomGridColDef<
  ProspectListProfile | ProspectListOrganization
>[] = [
  ...commonColumns,
  {
    field: 'speaking',
    headerName: 'Speaking',
    flex: 1,
    resizable: false,
    type: 'number',
    // custom column definitions not in GridColDef
    defaultFilter: true,
    filterable: false,
  },
  {
    field: 'title',
    headerName: 'Title',
    flex: 3,
    // custom column definitions not in GridColDef
    defaultFilter: true,
  },
  {
    field: 'organization',
    headerName: 'Organization',
    flex: 3,
    // custom column definitions not in GridColDef
    defaultFilter: true,
  },
];

export const organizationColumns: CustomGridColDef<
  ProspectListProfile | ProspectListOrganization
>[] = [
  ...commonColumns,
  {
    field: 'members',
    headerName: 'Members',
    sortable: true,
    flex: 1,
    type: 'number',
    // custom column definitions not in GridColDef
    defaultFilter: true,
    valueGetterId: 'sortNumberIntoBuckets',
  },
  {
    field: 'location',
    headerName: 'HQ location',
    sortable: true,
    flex: 3,
    // custom column definitions not in GridColDef
    defaultFilter: true,
  },
  {
    field: 'industry',
    headerName: 'Industry',
    sortable: true,
    flex: 2,
    // custom column definitions not in GridColDef
    defaultFilter: true,
  },
];
