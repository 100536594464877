import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material';
import { LoaderBlock } from 'components/common/Svgs/Loader';
import { AutoCompleteTags } from 'components/ProspectList/AutoCompleteWithUpload/AutoCompleteCustomTags';
import { AutoCompleteOption } from 'components/ProspectList/AutoCompleteWithUpload/AutoCompleteOption';
import { Option } from 'hooks/Prospects/useIcpFilter';

export interface VDXAutocompleteProps<TValue extends Option = Option> {
  options: TValue[];
  values: TValue[];
  slotProps?: {
    autocomplete?: Partial<React.ComponentProps<typeof MuiAutocomplete>>;
    textfield?: Partial<React.ComponentProps<typeof TextField>>;
    tags?: Partial<React.ComponentProps<typeof AutoCompleteTags>>;
  };

  onDeleteItem?: (item: TValue, index: number) => void;
}

export function VDXAutocomplete<TValue extends Option = Option>({
  values,
  slotProps,
  options,
  onDeleteItem,
}: VDXAutocompleteProps<TValue>) {
  return (
    <MuiAutocomplete
      fullWidth
      autoHighlight
      disableCloseOnSelect
      filterSelectedOptions
      getOptionLabel={(option) => (option as TValue).label}
      loadingText={<LoaderBlock />}
      multiple
      size="small"
      options={options}
      isOptionEqualToValue={(option: unknown, value: unknown) => {
        return (
          (option as TValue).value?.toLowerCase() ===
          (value as TValue).value?.toLowerCase()
        );
      }}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            placeholder="Enter manually..."
            data-testid="vdx-autocomplete"
            {...(slotProps?.textfield || {})}
          />
        );
      }}
      renderTags={(value, getTagProps) => (
        <AutoCompleteTags
          value={(value as TValue[])?.slice(0, 100)}
          isLink={false}
          getTagProps={getTagProps}
          allowCsvUpload
          length={value.length}
          handleDelete={onDeleteItem as (option: Option, index: number) => void}
          {...(slotProps?.tags || {})}
        />
      )}
      renderOption={(props, option) => {
        return (
          <AutoCompleteOption
            {...props}
            option={{
              label: (option as TValue).label,
              value: (option as TValue).value,
              disabled: (option as TValue).disabled,
            }}
          />
        );
      }}
      value={values}
      // Extend any MUI Autocomplete props
      {...(slotProps?.autocomplete || {})}
    />
  );
}
