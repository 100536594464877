import React, { useCallback } from 'react';
import {
  Card,
  CardContent,
  RadioGroup,
  Typography,
  Stack,
  FormControlLabel,
  Radio,
  Tooltip,
} from '@mui/material';

import { useListBuilder } from '../ListBuilderProvider';
import { PeopleJobTitleForm } from '../PeopleJobTitleForm';
import { PeopleInputMethod, PeopleInputMethods } from '../types';
import { CSVUploadProvider } from '../CSVUpload/CSVUploadContext';
import { PeopleCSVUploadForm } from '../CSVUpload/PeopleCSVUploadForm';
import { useWatch } from 'react-hook-form';
import { Option } from 'hooks/Prospects/useIcpFilter';
import { PeopleCRMInputForm } from '../PeopleCRMInputForm';
import { useUserState } from 'hooks/common/useUserState';
import { usePosthog } from 'analytics';

export function PeopleInputMethodCard() {
  const userState = useUserState();
  const { sendPosthogEvent } = usePosthog();

  const {
    state: { peopleInputMethod },
    dispatch,
    form,
    initialFormValues,
  } = useListBuilder();

  const listType = useWatch({
    control: form.control,
    name: 'listType',
  });

  const listEntityType = useWatch({
    control: form.control,
    name: 'listEntityType',
  });

  const listName = useWatch({
    control: form.control,
    name: 'listName',
  });

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newInputMethod = event.target.value as PeopleInputMethod;
      const inputMethodLabel = PeopleInputMethods[newInputMethod];

      // Determine event name based on input method
      let eventName = `Prospect Navigator > ${inputMethodLabel} selected`;

      // Send analytics event
      if (eventName) {
        sendPosthogEvent(eventName, {
          listName,
          listType,
          listContents: listEntityType,
          inputMethod: newInputMethod,
        });
      }

      dispatch({
        type: 'PEOPLE_INPUT_METHOD_CHANGED',
        peopleInputMethod: newInputMethod,
      });
    },
    [dispatch, sendPosthogEvent, listName, listType, listEntityType]
  );

  const handleFileUploaded = useCallback(
    (file) => {
      // Determine the field name based on the current input method
      const fieldName =
        peopleInputMethod === 'csv' ? 'includedPeople' : 'includedJobTitles';

      sendPosthogEvent('Prospect Navigator > CSV uploaded', {
        listName,
        listType,
        listContents: listEntityType,
        fieldName,
        fileName: file.name,
      });
    },
    [sendPosthogEvent, listName, listType, listEntityType, peopleInputMethod]
  );

  const onCsvProfileUploadChanged = useCallback(
    (newValues: Option[]) => {
      form.setValue('includedPeople', newValues);
    },
    [form]
  );

  const onJobTitleUploadChanged = useCallback(
    (newValues: Option[]) => {
      form.setValue(
        'includedJobTitles',
        newValues.map(({ value }) => value)
      );
    },
    [form]
  );

  // Determine which options to display based on other settings.
  const peopleInputMethodOptions = Object.entries(PeopleInputMethods).filter(
    (inputMethod) => {
      if (listType !== 'pipeline') {
        return inputMethod[0] !== 'crm';
      }

      return true;
    }
  );

  return (
    <Card sx={{ marginBottom: 2 }} data-testid="people-input-method-card">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Who do you want to track at events?
        </Typography>

        <Stack spacing={2}>
          <RadioGroup
            name="peopleInputMethod"
            // The empty string prevents an MUI warning about the uncontrolled component becoming controlled.
            value={peopleInputMethod || ''}
            onChange={onChange}
          >
            {peopleInputMethodOptions.map(([key, value]) => {
              if (key === 'crm' && !userState?.team?.crm_connected) {
                return (
                  <Tooltip title="Contact your CSM to enable CRM integration.">
                    <FormControlLabel
                      key={key}
                      value={key}
                      control={<Radio key={`${key}-radio`} />}
                      label={value}
                      data-testid={`radio-${key}`}
                      disabled={
                        key === 'crm' && !userState?.team?.crm_connected
                      }
                    />
                  </Tooltip>
                );
              }

              return (
                <FormControlLabel
                  key={key}
                  value={key}
                  control={<Radio key={`${key}-radio`} />}
                  label={value}
                  data-testid={`radio-${key}`}
                />
              );
            })}
          </RadioGroup>

          {peopleInputMethod === 'crm' ? <PeopleCRMInputForm /> : null}

          {peopleInputMethod === 'csv' ? (
            <CSVUploadProvider
              dataType="profiles"
              initialValues={initialFormValues.includedPeople}
              onValuesChanged={onCsvProfileUploadChanged}
              onFileUploaded={handleFileUploaded}
            >
              <PeopleCSVUploadForm />
            </CSVUploadProvider>
          ) : null}

          {peopleInputMethod === 'jobTitles' ? (
            <CSVUploadProvider
              dataType="jobTitles"
              initialValues={initialFormValues.includedJobTitles.map(
                (value) => ({ value, label: value })
              )}
              onValuesChanged={onJobTitleUploadChanged}
              onFileUploaded={handleFileUploaded}
            >
              <Typography variant="h6" gutterBottom>
                Select job titles to include below.
              </Typography>
              <PeopleJobTitleForm fieldName="includedJobTitles" />
            </CSVUploadProvider>
          ) : null}
        </Stack>
      </CardContent>
    </Card>
  );
}
