import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  SelectChangeEvent,
} from '@mui/material';
import { useListBuilder } from './ListBuilderProvider';
import { ListBuilderFormSchema } from './formSchema';
import { Path, Controller, useWatch } from 'react-hook-form';
import { crmApi } from 'redux/reducers/api/crm';
import { useUserState } from 'hooks/common/useUserState';
import { LoaderBlock } from 'components/common/Svgs/Loader';
import { useCallback, useEffect, useMemo } from 'react';
import { Option } from 'hooks/Prospects/useIcpFilter';
import { usePosthog } from 'analytics';

export function PeopleCRMInputForm() {
  const { team } = useUserState();
  const { form } = useListBuilder();
  const { data, isLoading } = crmApi.useGetCrmFilterOptionsQuery(team.uuid);
  const { sendPosthogEvent } = usePosthog();

  // Watch list related fields to add to analytics events
  const listName = useWatch({ control: form.control, name: 'listName' });
  const listType = useWatch({ control: form.control, name: 'listType' });
  const listEntityType = useWatch({
    control: form.control,
    name: 'listEntityType',
  });

  const handleCRMFieldChange = useCallback(
    (fieldName: string, value: string[]) => {
      if (value.length > 0) {
        sendPosthogEvent('Prospect Navigator > CRM Field Changed', {
          listName,
          listType,
          listContents: listEntityType,
          fieldName,
          value,
        });
      }
    },
    [listName, listType, listEntityType, sendPosthogEvent]
  );

  // Reset values when the component unmounts.
  useEffect(() => {
    return () => {
      form.resetField('includedContactOwners');
    };
  }, [form]);

  const options = useMemo(
    () =>
      data?.success
        ? data.results.grouped_crm_filters.OWNER?.map((item) => ({
            label: item.label,
            value: item.label, // DEV-3683
          }))
        : [],
    [data]
  );

  useEffect(() => {
    return () => {
      form.resetField('includedContactOwners');
    };
  }, [form]);

  const selectAllContacts = useCallback(() => {
    form.setValue('includedContactOwners' as Path<ListBuilderFormSchema>, [
      ...options.map(({ value }: Option) => value),
    ]);
  }, [form, options]);

  const deselectAllContacts = useCallback(() => {
    form.setValue('includedContactOwners' as Path<ListBuilderFormSchema>, []);
  }, [form]);

  if (isLoading) {
    return <LoaderBlock />;
  }

  return (
    <Stack spacing={2} data-testid="people-crm-input-form">
      <FormControl fullWidth variant="outlined">
        <InputLabel id="contact-owner-select-label">Contact owner</InputLabel>
        <Controller
          name="includedContactOwners"
          control={form.control}
          render={({ field }) => (
            <Select
              label="Contact owner"
              labelId="contact-owner-select-label"
              data-testid="contact-owner-select"
              multiple
              {...field}
              onChange={(e: SelectChangeEvent<string[]>) => {
                field.onChange(e);
                handleCRMFieldChange(
                  'includedCompanyOwners',
                  e.target.value as string[]
                );
              }}
            >
              {options?.map((owner) => (
                <MenuItem key={owner.value} value={owner.value}>
                  {owner.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>

      <Stack direction="row" justifyContent="center" spacing={2}>
        <Button onClick={selectAllContacts}>Select all contacts </Button>
        <Button onClick={deselectAllContacts}>Deselect all contacts </Button>
      </Stack>
    </Stack>
  );
}
