import { Card, CardContent, Typography, Tabs, Tab, Box } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useListBuilder } from '../ListBuilderProvider';
import { PropsWithChildren, useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import { usePosthog } from 'analytics';

interface TabPanelProps extends PropsWithChildren<{}> {
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`list-content-tabpanel-${index}`}
      aria-labelledby={`list-content-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

export function ListContentsCard() {
  const { form } = useListBuilder();
  const { sendPosthogEvent } = usePosthog();
  const listEntityType = useWatch({
    control: form.control,
    name: 'listEntityType',
  });
  const tabValue = listEntityType === 'profiles' ? 0 : 1;

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      const newEntityType = newValue === 0 ? 'profiles' : 'organizations';

      form.setValue('listEntityType', newEntityType);

      // Send analytics event when entity type changes
      sendPosthogEvent('Prospect Navigator > List Contents Selected', {
        listContents: newEntityType,
      });
    },
    [form, sendPosthogEvent]
  );

  return (
    <Card data-testid="list-contents-card">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          List Contents
        </Typography>

        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="People" sx={{ fontSize: '1rem' }} />
          <Tab
            label="Organizations"
            sx={{ fontSize: '1rem' }}
            data-value="organizations"
          />
        </Tabs>

        <TabPanel value={tabValue} index={0} data-testid="people-tab-content">
          <Typography variant="body1" gutterBottom>
            Build People Lists to:
          </Typography>
          <List
            sx={{
              listStyleType: 'disc',
            }}
          >
            <ListItem sx={{ display: 'list-item', ml: '1rem' }}>
              Discover new events with the people you care about
            </ListItem>
            <ListItem sx={{ display: 'list-item', ml: '1rem' }}>
              Evaluate who is attending events
            </ListItem>
            <ListItem sx={{ display: 'list-item', ml: '1rem' }}>
              Get alerts when key people are added to events
            </ListItem>
          </List>
        </TabPanel>

        <TabPanel
          value={tabValue}
          index={1}
          data-testid="organizations-tab-content"
        >
          <Typography variant="body1" gutterBottom>
            Build Organization Lists to:
          </Typography>
          <List
            sx={{
              listStyleType: 'disc',
            }}
          >
            <ListItem sx={{ display: 'list-item', ml: '1rem' }}>
              Discover new events with the companies you care about
            </ListItem>
            <ListItem sx={{ display: 'list-item', ml: '1rem' }}>
              Evaluate companies that are sponsoring events
            </ListItem>
            <ListItem sx={{ display: 'list-item', ml: '1rem' }}>
              Get alerts when target accounts are added to events
            </ListItem>
          </List>
        </TabPanel>
      </CardContent>
    </Card>
  );
}
