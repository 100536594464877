import { useEffect, useMemo, useState } from 'react';
import { Badge, Box, Button, Container, Typography } from '@mui/material';
import { withAppAuthenticationRequired } from 'components/common/Auth/withAppAuthenticationRequired';
import { SectionLayout } from 'components/common/Widgets/SectionLayout';
import { useHistory, useParams } from 'react-router';
import { prospectsApi } from 'redux/reducers/api/prospects';
import { DataGridProvider } from 'components/common/DataGrid/DataGridProvider';
import {
  ProspectListProfile,
  ProspectListOrganization,
  ProspectListPermissions,
} from 'modules/prospects/types';
import { getListPaths } from 'utils/util';
import { MuiIconManifest } from 'utils/iconManifest';
import { useGridMetaData } from 'components/common/Context/PageDataContext';
import { LimitedResultsAlert } from 'components/ProspectList/LimitedResultsAlert';
import { ListEntityTypeIcon } from 'components/common/Lists/ListEntityType';
import {
  organizationColumns,
  profileColumns,
} from 'components/ProspectList/listViewGridConstants';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { Link } from 'react-router-dom';

// #region column definitions

/**
 * Core component for rendering the profiles and organizations of a list using DataGridProvider.
 *
 * Jobs of this component:
 *
 * - Fetch all profiles and organizations of a list.
 * - Render the profiles and organizations using DataGridProvider.
 */
export function _ViewListPage() {
  const history = useHistory();
  const { listUuid: uuid } = useParams<{ listUuid: string }>();

  const { data: listData, isLoading: listIsLoading } =
    prospectsApi.useGetListQuery(uuid);

  const { data: profileData, isLoading: profileDataLoading } =
    prospectsApi.useGetProspectListProfilesQuery(uuid);
  const { data: orgData, isLoading: orgDataLoading } =
    prospectsApi.useGetProspectListOrganizationsQuery(uuid);

  const canEdit = useMemo(
    () =>
      listData?.results?.my_permissions?.includes(
        ProspectListPermissions.Write
      ),
    [listData]
  );

  const flagEnabled = useFeatureFlagEnabled('prospect-navigator-list-builder');

  const [selectedTabValue, setSelectedTabValue] = useState('profiles');
  const isLoading =
    selectedTabValue === 'profiles' ? profileDataLoading : orgDataLoading;
  const rows =
    selectedTabValue === 'profiles'
      ? profileData?.success
        ? profileData.profiles
        : []
      : orgData?.success
      ? orgData.organizations
      : [];

  const gridRef = useGridMetaData('list');

  // when data loads and there is no profile data, change tab to organizations
  useEffect(() => {
    if (
      !orgDataLoading &&
      !profileDataLoading &&
      profileData?.success &&
      profileData?.profiles.length === 0
    ) {
      setSelectedTabValue('organizations');
    }
  }, [profileData, profileDataLoading, orgData, orgDataLoading]);

  const columns = useMemo(() => {
    if (selectedTabValue === 'profiles') {
      return profileColumns;
    } else {
      return organizationColumns;
    }
  }, [selectedTabValue]);

  return (
    <>
      <SectionLayout
        title={
          <Box>
            <ListEntityTypeIcon
              value={listData?.results?.list_entity_type || null}
              slotProps={{
                icon: {
                  sx: {
                    width: '30px',
                    height: '30px',
                    marginRight: 1,
                    verticalAlign: 'middle',
                  },
                },
              }}
            />
            <Typography
              variant="h4"
              component="h1"
              data-testid="section-layout-title"
              sx={{
                verticalAlign: 'middle',
                display: 'inline-block',
              }}
            >
              {listData?.results?.name ? listData?.results?.name : ''}
            </Typography>
          </Box>
        }
        slotProps={{
          container: {
            maxWidth: 'lg',
          },
        }}
        rightsideToolbar={
          <>
            {!listIsLoading &&
            flagEnabled &&
            canEdit &&
            getListPaths(listData.results).editV4Path ? (
              <Link to={getListPaths(listData.results).editV4Path!}>
                <Button
                  startIcon={<MuiIconManifest.SearchIcon />}
                  variant="roundedOutlined"
                  color="primary"
                  sx={{ mr: 5 }}
                >
                  Edit w/Prospect Navigator
                  <Badge
                    badgeContent={'New'}
                    color="secondary"
                    sx={{ top: -15, right: -15 }}
                  />
                </Button>
              </Link>
            ) : null}
            <Button
              startIcon={<MuiIconManifest.ManageAccountsIcon />}
              variant="rounded"
              color="primary"
              onClick={(e) => {
                if (listIsLoading) {
                  e.preventDefault();
                }

                if (!listIsLoading && listData?.results) {
                  history.push(getListPaths(listData.results).editPath);
                }
              }}
            >
              Edit list
            </Button>
          </>
        }
      />
      <Container maxWidth="lg">
        <LimitedResultsAlert list={rows} />
        <DataGridProvider<ProspectListProfile | ProspectListOrganization>
          apiRef={gridRef.ref}
          rows={rows}
          columns={columns}
          rowsLoading={isLoading}
          singularRowName={
            selectedTabValue === 'profiles' ? 'profile' : 'organization'
          }
          slotProps={{
            datagrid: {
              getRowId: (row) => row.uuid,
              noRowsOverlayText: `There are no ${selectedTabValue} in this list.`,
            },
          }}
        />
      </Container>
    </>
  );
}

export const ViewListPage = withAppAuthenticationRequired(_ViewListPage);
