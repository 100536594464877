import { Box, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useCSVUploadContext } from './CSVUploadContext';
import { FileInput } from './FileInput';
import { VDXAutocomplete } from '../VDXAutocomplete';
import { useListBuilder } from '../ListBuilderProvider';
import { OrganizationsCSVColumnsForm } from './OrganizationsCSVColumnsForm';
import { useEffect } from 'react';

export function PeopleOrganizationsCSVUploadForm() {
  const { form } = useListBuilder();

  const {
    values,
    file,
    fetchedOptions,
    handleAutocompleteChange,
    handleFileInputChange,
    handleFileInputDrop,
    handleRemoveItem,
    handleTextInputChange,
    handleKeyDown,
  } = useCSVUploadContext();

  useEffect(() => {
    return () => {
      form.resetField('includedOrganizations');
    };
  }, [form]);

  return (
    <Box data-testid="people-organizations-csv-upload-form">
      <Typography variant="h6" gutterBottom>
        Upload CSV
      </Typography>

      {/* File uploader */}
      <FileInput
        onChange={handleFileInputChange}
        onDrop={handleFileInputDrop}
      />

      {/* Column matcher */}
      {!!file ? <OrganizationsCSVColumnsForm /> : null}

      {!file ? (
        <>
          <Box sx={{ textAlign: 'center', my: 3 }}>
            <Typography>OR</Typography>
          </Box>
          <Typography variant="h6" gutterBottom>
            Enter manually
          </Typography>
        </>
      ) : null}

      {/* Autocomplete */}
      <Controller
        name="includedOrganizations"
        control={form.control}
        render={({ field }) => (
          <VDXAutocomplete
            values={values}
            options={fetchedOptions}
            slotProps={{
              autocomplete: {
                freeSolo: true,
                limitTags: 5,
                onChange: handleAutocompleteChange,
                onKeyDown: handleKeyDown,
              },
              textfield: {
                ...field,
                placeholder: values.length
                  ? 'Enter additional organizations (optional)...'
                  : 'Search for organizations by name...',
                onChange: handleTextInputChange,
              },
              tags: {
                limitTags: 10,
              },
            }}
            onDeleteItem={handleRemoveItem}
          />
        )}
      />
    </Box>
  );
}
