import React, { useCallback, useEffect, useMemo } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { useListBuilder } from '../ListBuilderProvider';
import { NaicsIndustrySelect } from 'components/Wizard/QuestionTypes/NaicsIndustrySelectQuestion/NaicsIndustrySelect';
import { newPrunedCodeInfos } from 'utils/naics';

export function OrganizationsIndustryCard() {
  const { form: mainForm } = useListBuilder();

  // Reset values when the component unmounts.
  useEffect(() => {
    return () => {
      mainForm.setValue('includedNaics2022Codes', []);
    };
  }, [mainForm]);

  const handleChange = useCallback(
    (newValue: string[]) => {
      mainForm.setValue('includedNaics2022Codes', newValue);
    },
    [mainForm]
  );

  const minDigits = 2;
  const maxDigits = 4;

  const codeInfos = useMemo(
    () =>
      newPrunedCodeInfos({
        minDigits: minDigits,
        maxDigits: maxDigits,
      }),
    [minDigits, maxDigits]
  );

  return (
    <Card sx={{ marginBottom: 2 }} data-testid="organizations-industry-card">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          What industries do you want to include?
        </Typography>

        <NaicsIndustrySelect
          codeInfos={codeInfos}
          value={mainForm.watch('includedNaics2022Codes')}
          onChange={handleChange}
        />
      </CardContent>
    </Card>
  );
}
